<template>
  <div>

    <div class="generateTravelPlanOuterContainer">

      <div id="my-node3" class="" v-if="intermodalReservationData && reservationData && customer && coordinator">


        <div class="generateTravelPlanContainer">
          <img :src="require('@/assets/images/logo/logotip.svg')" class="logo">

          <div class="clearfix"></div>

          <div class="headerSection">

            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
              <div style="text-align: right;">
                <h1 class="title mb-1">Potovalni načrt</h1>
                <h2 class="type mb-1">
                <span class="value font-bold">
                    <span v-if="intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION'">
                        Od DOMA do DESTINACIJE
                    </span>
                    <span v-if="intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME'">
                        Od DESTINACIJE do DOMA
                    </span>
                </span>
                </h2>
                <h2 class="date mb-3">
                  {{ moment(reservationData.departure_time).format('dddd') }},
                  {{ moment(reservationData.departure_time).format('D. MM. YYYY') }}
                </h2>
              </div>
            </vs-col>


            <vs-alert color="primary" class="my-5 infoSection">
              <img :src="require('@/assets/images/content/travelPlan/info.svg')" class="infoIcon"/>
              Za vsa vprašanja na poti vam je na voljo
              {{ coordinator.gender === 'female' ? 'koordinatorka' : kooridnator }} {{ coordinator.first_name }}
              {{ coordinator.last_name }} na
              <h1 style="display: inline;">{{ reservationData.unit.phone_reservations }}</h1>.
            </vs-alert>
          </div>

          <div class="content">
            <div class="intermodalReservationOverview mb-2">

              <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                  <div style="width: 100%">
                    <div class="iconContainer">
                      <img v-if="intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION'"
                           :src="require('@/assets/images/content/travelPlan/dom.svg')" class="locationIcon"/>
                      <img v-if="intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME'"
                           :src="require('@/assets/images/content/travelPlan/destinacija.svg')" class="locationIcon"/>
                    </div>
                    <div class="departure mb-2 mt-8">
                      <h2>ODHOD
                        <span v-if="intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION'">ob {{
                            moment(reservationData.departure_time).format('HH:mm')
                          }}
                        </span>
                        <span v-if="intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME'">ob

                          <span v-if="!intermodalReservationData.requireTaxi">
                            {{
                              moment(parseInt(intermodalReservationInstructions[0].transit_details.departure_time.value + '000')).format('HH:mm')
                            }}
                          </span>

                          <span v-if="intermodalReservationData.requireTaxi">
                            {{
                              calculatedTaxiDepartureTime
                            }}
                          </span>

                        </span>
                      </h2>
                      <div class="section address mt-2">
                        <div>
                      <span class="value font-bold">
                    <span v-if="intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION'">
                      {{ customer.addresses.quick_search }}
                    </span>
                    <span v-if="intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME'">
                      <span v-if="!intermodalReservationData.requireTaxi">
                        <span v-if="intermodalReservationData.departureStop.addressName">{{
                            intermodalReservationData.departureStop.addressName
                          }}</span>, {{ intermodalReservationData.departureStop.quickSearch }}
                      </span>

                      <span v-if="intermodalReservationData.requireTaxi">
                        <span v-if="intermodalReservationData.connectedIntermodalReservation.finalDestination.addressName">{{
                            intermodalReservationData.connectedIntermodalReservation.finalDestination.addressName
                          }}</span>, {{ intermodalReservationData.connectedIntermodalReservation.finalDestination.quickSearch }}
                      </span>
                    </span>

                  </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                  <img
                    :src="require('@/assets/images/content/travelPlan/puscica.svg')" class="arrowIcon"/>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
                  <div style="width: 100%">
                    <div class="iconContainer">
                      <img v-if="intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME'"
                           :src="require('@/assets/images/content/travelPlan/dom.svg')" class="locationIcon"/>
                      <img v-if="intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION'"
                           :src="require('@/assets/images/content/travelPlan/destinacija.svg')" class="locationIcon"/>
                    </div>
                    <div class="arrival mb-2 mt-8">
                      <h2>PRIHOD
                        <span v-if="intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION'">ob

                          <span v-if="!intermodalReservationData.requireTaxi">
                             {{moment(intermodalReservationData.arrivalTime).format('HH:mm') }}
                          </span>
                          <span v-if="intermodalReservationData.requireTaxi">
                              {{ calculatedTaxiArrivalTime }}
                          </span>

                        </span>
                        <span v-if="intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME'">ob {{
                            moment(reservationData.arrival_time).format('HH:mm')
                          }}</span>
                      </h2>
                      <div class="section address mt-2">
                        <div>
                      <span class="value font-bold">
                    <span v-if="intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION'">
                        <span
                          v-if="intermodalReservationData.finalDestination.addressName">{{
                            intermodalReservationData.finalDestination.addressName
                          }}, </span>{{ intermodalReservationData.finalDestination.quickSearch }}
                    </span>
                    <span v-else>
                      {{ customer.addresses.quick_search }}
                    </span>
                  </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </vs-col>
              </vs-row>

              <div class="section mt-2 mb-5">
                <div class="generalNotes">
                  <h2><span>!</span>OPOMBE:</h2>
                  <span class="value">{{
                      intermodalReservationData.notes ? intermodalReservationData.notes : '/'
                    }}</span>
                </div>
              </div>

              <div class="steps mt-3">

                <div v-if="intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION'">
                  <div class="sopotnikiStep step">

                    <div class="stepCounter">KORAK <span>1</span></div>
                    <div class="transportationTypeIconContainer">
                      <img :src="require('@/assets/images/content/travelPlan/Sopotniki-vozilo.svg')"
                           class="transportationTypeIcon"/>
                    </div>
                    <div class="transportationTypeDescriptionContainer">
                      <span>prevoz z Zavodom Sopotniki</span>
                    </div>
                    <div class="clearfix"></div>
                    <vs-row class="stepInnerContainer">
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                        <div class="arrival">
<!--                          <h2>ODHOD <span>ob {{ moment(calculateDepartureTime).format('HH:mm') }}</span>-->
<!--                          </h2>-->
                          <h2>ODHOD <span>ob {{ moment(reservationData.departure_time).format('HH:mm') }}</span>
                          </h2>
                          <div class="section address mt-2">
                            <div>
                      <span class="value font-bold">
                        {{ customer.addresses.quick_search }}
                      </span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2"></vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                        <div class="arrival">
                          <h2>PRIHOD <span>ob {{ moment(reservationData.arrival_time).format('HH:mm') }}</span>
                          </h2>
                          <div class="section address mt-2">
                            <div>
                      <span class="value font-bold">
                        <span v-if="reservationData.arrival.address_name">{{
                            reservationData.arrival.address_name
                          }}, </span> {{ reservationData.arrival.quick_search }}
                        </span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>

                  <div class="publicTransportSteps step">

                    <div v-for="(step, index) in intermodalReservationInstructions" :key="'step_'+index">

                      <div class="stepCounter">KORAK <span>{{ index + 2 }}</span></div>
                      <div class="transportationTypeIconContainer">
                        <img
                          :src="step.transit_details.line.vehicle.name.toLowerCase() === 'vlak' ? require('@/assets/images/content/travelPlan/vlak1.svg') : require('@/assets/images/content/travelPlan/bus.svg')"
                          class="transportationTypeIcon"/>
                      </div>
                      <div class="transportationTypeDescriptionContainer">
                        <span>{{ step.transit_details.line.vehicle.name.toLowerCase() }}  <span
                          v-if="step.transit_details.headsign">z oznako "{{
                            step.transit_details.headsign
                          }}"</span></span>
                        <span v-if="step.transit_details && step.transit_details.line"
                              class="operatorName">{{ step.transit_details.line.agencies[0].name }}</span>
                      </div>
                      <div class="clearfix"></div>
                      <vs-row class="stepInnerContainer">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                          <div class="arrival">
                            <h2>ODHOD <span>ob {{
                                moment(parseInt(step.transit_details.departure_time.value + '000')).format('HH:mm')
                              }}</span>
                            </h2>
                            <div class="section address mt-2">
                              <div>
                     <span class="value font-bold">
                            <span v-if="intermodalReservationData.departureStop.addressName">{{
                                intermodalReservationData.departureStop.addressName
                              }}</span>, {{ intermodalReservationData.departureStop.quickSearch }}
                          </span>
                              </div>
                            </div>
                          </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2"></vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                          <div class="arrival">
                            <h2>PRIHOD <span>ob {{
                                moment(parseInt(step.transit_details.arrival_time.value + '000')).format('HH:mm')
                              }}</span>
                            </h2>
                            <div class="section address mt-2">
                              <div>
                      <span class="value font-bold">
                          <span v-if="intermodalReservationData.arrivalStop.addressName">{{
                              intermodalReservationData.arrivalStop.addressName
                            }}</span>, {{ intermodalReservationData.arrivalStop.quickSearch }}
                        </span>
                              </div>
                            </div>
                          </div>
                        </vs-col>
                      </vs-row>
                      <div class="generalNotes" style="margin-top: -1px;">
                        <h2><span>!</span>OPOMBE:</h2>
                        <span class="value">{{
                            step.notes ? step.notes : '/'
                          }}</span><br/>
                        <span class="value">Čas potovanja: {{ step.duration.text }} <span class="delimiter">/</span> Dolžina potovanja: {{
                            step.distance.text
                          }} <span class="delimiter">/</span> <span
                            v-if="step.transit_details && step.transit_details.num_stops">Število vmesnih postaj: {{
                              step.transit_details.num_stops
                            }}</span></span>
                      </div>
                    </div>
                  </div>
                  <div class="taxiStep step" v-if="intermodalReservationData.requireTaxi">

                    <div class="stepCounter">KORAK <span>3</span></div>
                    <div class="transportationTypeIconContainer">
                      <img :src="require('@/assets/images/content/travelPlan/taksi.svg')"
                           class="transportationTypeIcon"/>
                    </div>
                    <div class="transportationTypeDescriptionContainer">
                      <span>taksi prevoz</span>
                    </div>
                    <div class="clearfix"></div>
                    <vs-row class="stepInnerContainer">
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                        <div class="arrival">
                          <h2>ODHOD <span>ob {{
                              moment(parseInt(intermodalReservationInstructions[intermodalReservationInstructions.length - 1].transit_details.arrival_time.value + '000')).add(intermodalTransportationThreshold, 'seconds').format('HH:mm')
                            }}</span>
                          </h2>
                          <div class="section address mt-2">
                            <div>
                      <span class="value font-bold"><span v-if="intermodalReservationData.arrivalStop.addressName">{{
                          intermodalReservationData.arrivalStop.addressName
                        }}</span>, {{ intermodalReservationData.arrivalStop.quickSearch }}</span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2"></vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                        <div class="arrival">
                          <h2>PRIHOD <span>ob {{ calculatedTaxiArrivalTime }}</span>
                          </h2>
                          <div class="section address mt-2">
                            <div>
                      <span class="value font-bold">
                          <span
                            v-if="intermodalReservationData.finalDestination.addressName">{{
                              intermodalReservationData.finalDestination.addressName
                            }}, </span>{{ intermodalReservationData.finalDestination.quickSearch }}
                        </span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                    </vs-row>
                    <div class="generalNotes" style="margin-top: -1px;" v-if=" intermodalReservationData.taxiNotes">
                      <h2><span>!</span>OPOMBE:</h2>
                      <span class="value">{{
                          intermodalReservationData.taxiNotes
                        }}</span>
                    </div>
                  </div>
                </div>

                <div v-if="intermodalReservationData.type === 'FROM_DESTINATION_TO_HOME'">

                  <div class="taxiStep step" v-if="intermodalReservationData.requireTaxi">

                    <div class="stepCounter">KORAK <span>1</span></div>
                    <div class="transportationTypeIconContainer">
                      <img :src="require('@/assets/images/content/travelPlan/taksi.svg')"
                           class="transportationTypeIcon"/>
                    </div>
                    <div class="transportationTypeDescriptionContainer">
                      <span>taksi prevoz</span>
                    </div>
                    <div class="clearfix"></div>
                    <vs-row class="stepInnerContainer">
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                        <div class="arrival">
                          <h2>ODHOD <span>ob {{
                              calculatedTaxiDepartureTime
                            }}</span>
                          </h2>
                          <div class="section address mt-2">
                            <div>
                      <span class="value font-bold">
                        <span v-if="intermodalReservationData.connectedIntermodalReservation.finalDestination.addressName">{{
                            intermodalReservationData.connectedIntermodalReservation.finalDestination.addressName
                          }}</span>, {{ intermodalReservationData.connectedIntermodalReservation.finalDestination.quickSearch }}
                        </span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2"></vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                        <div class="arrival">
                          <h2>PRIHOD <span>ob {{ calculatedTaxiArrivalTime }}</span>
                          </h2>
                          <div class="section address mt-2">
                            <div>
                      <span class="value font-bold">
                          <span class="value font-bold">
                            <span v-if="intermodalReservationData.departureStop.addressName">{{
                                intermodalReservationData.departureStop.addressName
                              }}</span>, {{ intermodalReservationData.departureStop.quickSearch }}
                          </span>
                      </span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                    </vs-row>
                    <div class="generalNotes" style="margin-top: -1px;" v-if=" intermodalReservationData.taxiNotes">
                      <h2><span>!</span>OPOMBE:</h2>
                      <span class="value">{{
                          intermodalReservationData.taxiNotes
                        }}</span>
                    </div>
                  </div>


                  <div class="publicTransportSteps step">

                    <div v-for="(step, index) in intermodalReservationInstructions" :key="'step_'+index">

                      <div class="stepCounter">KORAK <span>{{
                          index + (intermodalReservationData.requireTaxi ? 2 : 1)
                        }}</span></div>
                      <div class="transportationTypeIconContainer">
                        <img
                          :src="step.transit_details.line.vehicle.name.toLowerCase() === 'vlak' ? require('@/assets/images/content/travelPlan/vlak1.svg') : require('@/assets/images/content/travelPlan/bus.svg')"
                          class="transportationTypeIcon"/>
                      </div>
                      <div class="transportationTypeDescriptionContainer">
                        <span>{{ step.transit_details.line.vehicle.name.toLowerCase() }}  <span
                          v-if="step.transit_details.headsign">z oznako "{{
                            step.transit_details.headsign
                          }}"</span></span>
                        <span v-if="step.transit_details && step.transit_details.line"
                              class="operatorName">{{ step.transit_details.line.agencies[0].name }}</span>
                      </div>
                      <div class="clearfix"></div>
                      <vs-row class="stepInnerContainer">
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                          <div class="arrival">
                            <h2>ODHOD <span>ob {{
                                moment(parseInt(step.transit_details.departure_time.value + '000')).format('HH:mm')
                              }}</span>
                            </h2>
                            <div class="section address mt-2">
                              <div>
                     <span class="value font-bold">
                            <span v-if="intermodalReservationData.departureStop.addressName">{{
                                intermodalReservationData.departureStop.addressName
                              }}</span>, {{ intermodalReservationData.departureStop.quickSearch }}
                          </span>
                              </div>
                            </div>
                          </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2">
                        </vs-col>
                        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                          <div class="arrival">
                            <h2>PRIHOD <span>ob {{
                                moment(parseInt(step.transit_details.arrival_time.value + '000')).format('HH:mm')
                              }}</span>
                            </h2>
                            <div class="section address mt-2">
                              <div>
                      <span class="value font-bold">
                          <span v-if="intermodalReservationData.arrivalStop.addressName">{{
                              intermodalReservationData.arrivalStop.addressName
                            }}</span>, {{ intermodalReservationData.arrivalStop.quickSearch }}
                        </span>
                              </div>
                            </div>
                          </div>
                        </vs-col>
                      </vs-row>
                      <div class="generalNotes" style="margin-top: -1px;">
                        <h2><span>!</span>OPOMBE:</h2>
                        <span class="value">{{
                            step.notes ? step.notes : '/'
                          }}</span><br/>
                        <span class="value">Čas potovanja: {{ step.duration.text }} <span class="delimiter">/</span> Dolžina potovanja: {{
                            step.distance.text
                          }} <span class="delimiter">/</span> <span
                            v-if="step.transit_details && step.transit_details.num_stops">Število vmesnih postaj: {{
                              step.transit_details.num_stops
                            }}</span></span>
                      </div>
                    </div>
                  </div>


                  <div class="sopotnikiStep step">

                    <div class="stepCounter">KORAK
                      <span>{{
                          intermodalReservationInstructions.length + (intermodalReservationData.requireTaxi ? 2 : 1)
                        }}</span>
                    </div>
                    <div class="transportationTypeIconContainer">
                      <img :src="require('@/assets/images/content/travelPlan/Sopotniki-vozilo.svg')"
                           class="transportationTypeIcon"/>
                    </div>
                    <div class="transportationTypeDescriptionContainer">
                      <span>prevoz z Zavodom Sopotniki</span>
                    </div>
                    <div class="clearfix"></div>
                    <vs-row class="stepInnerContainer">
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                        <div class="arrival">
                          <h2>ODHOD <span>ob {{ moment(reservationData.departure_time).format('HH:mm') }}</span>
                          </h2>
                          <div class="section address mt-2">
                            <div>
                      <span class="value font-bold">
                        <span v-if="reservationData.departure.address_name">{{
                            reservationData.departure.address_name
                          }}, </span> {{ reservationData.departure.quick_search }}
                      </span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="2"></vs-col>
                      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-w="5">
                        <div class="arrival">
                          <h2>PRIHOD <span>ob {{ moment(reservationData.arrival_time).format('HH:mm') }}</span>
                          </h2>
                          <div class="section address mt-2">
                            <div>
                      <span class="value font-bold">
                        <span v-if="reservationData.arrival.address_name">{{
                            reservationData.arrival.address_name
                          }}, </span> {{ reservationData.arrival.quick_search }}
                        </span>
                            </div>
                          </div>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'IntermodalReservationTravelPlan',
  components: {},
  props: {},
  data() {
    return {
      customersData: null,
      intermodalReservationData: null,
      intermodalReservationInstructions: null,
      reservationData: null,
      customer: null,
      volunteersData: null,
      coordinator: null,
      calculatedTaxiDepartureTime: null,
      calculatedTaxiArrivalTime: null,
      intermodalTransportationThreshold: 15 * 60, // threshold for arriving to bus/train station on time
    }
  },
  computed: {
    calculateDepartureTime() {
      const _this = this;
      let durationHours = _this.reservationData.route_time.split(' ')[0].replace('h', '');
      let durationMinutes = _this.reservationData.route_time.split(' ')[1].replace('min', '');
      return _this.moment(_this.reservationData.arrival_time).subtract(durationHours, 'hours').subtract(durationMinutes, 'minutes');
    }
  },
  watch: {},
  mounted() {
    const _this = this;

    _this.$vs.loading();

    console.log(Vue.prototype.$http.defaults.headers);
    console.log(" this.$route.query", this.$route.query);

    if (_this.$route.params.intermodalReservationId) {
      if (this.$route.query.jwt) {

        Vue.prototype.$http.interceptors.request.use(
          config => {
            const token = this.$route.query.jwt;
            if (token) {
              if (config.url.includes('https://api.sopotniki.org/')) {
                config.headers.common["Authorization"] = 'Bearer ' + token;
              } else {
                config.headers.common["Authorization"] = token;
              }
            }
            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );
      }

      let p1 = _this.getCustomers();
      let p2 = _this.getVolunteers();
      Promise.all([p1, p2]).then(async () => {
        await _this.getIntermodalReservation();
        if(_this.intermodalReservationData.type === 'FROM_HOME_TO_DESTINATION') {
          _this.calculateTaxiDepartureTimeFromHomeToDestination(_this.moment(parseInt(_this.intermodalReservationInstructions[_this.intermodalReservationInstructions.length - 1].transit_details.arrival_time.value + '000')).add(_this.intermodalTransportationThreshold, 'seconds').format('HH:mm'), _this.intermodalReservationData.arrivalStop.quickSearch, _this.intermodalReservationData.finalDestination.quickSearch);
        } else {
          _this.calculateTaxiDepartureTimeFromDestinationToHome(_this.moment(parseInt(_this.intermodalReservationInstructions[0].transit_details.departure_time.value + '000')), _this.intermodalReservationData.connectedIntermodalReservation.finalDestination.quickSearch, _this.intermodalReservationData.departureStop.quickSearch);
        }
      });

    } else {
      _this.$vs.loading.close();
    }


  },
  methods: {
    async getCustomers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/unit/' + _this.$route.params.unitId)
        .then((res) => {
          _this.customersData = res.data.data;
          console.log("customersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getVolunteers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/unit/' + _this.$route.params.unitId)
        .then((res) => {
          _this.volunteersData = res.data.data;
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getIntermodalReservation() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'intermodal-reservations/' + _this.$route.params.intermodalReservationId)
        .then((res) => {

          _this.intermodalReservationData = res.data.data;
          _this.intermodalReservationInstructions = JSON.parse(res.data.data.instructions);
          _this.reservationId = res.data.data.reservation.id;
          _this.finalDestination = res.data.data.finalDestination;

          if (res.data.data.type === 'FROM_HOME_TO_DESTINATION') {
            _this.intermodalReservationData.departure = res.data.data.departureStop;
            _this.intermodalReservationData.destination = res.data.data.arrivalStop;
          } else {
            _this.intermodalReservationData.departure = res.data.data.arrivalStop;
            _this.intermodalReservationData.destination = res.data.data.departureStop;
          }

          // _this.customer = res.data.data.customer;
          // _this.routeInstructionsData = JSON.parse(res.data.data.instructions);
          // _this.intermodalReservationData.requireTaxi = res.data.data.requireTaxi;
          // _this.departureTime = res.data.data.departureTime;
          // _this.arrivalTime = res.data.data.arrivalTime;

          console.log("intermodalReservationData: ", res.data.data);
          console.log("intermodalReservationInstructions", _this.intermodalReservationInstructions);
          console.log("customer: ", _this.customer);

          _this.getReservation().then(() => {
            _this.$vs.loading.close();
          });


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getReservation() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'reservations/' + _this.reservationId)
        .then((res) => {

          _this.reservationData = res.data.data;
          console.log("volunteer:", res.data.data.unit.volunteers_id);
          _this.customer = _.filter(_this.customersData, {id: res.data.data.customer_id})[0];
          _this.coordinator = _.filter(_this.volunteersData, {id: res.data.data.unit.volunteers_id})[0];
          // _this.volunteer = _.filter(_this.volunteersData, {id: res.data.data.volunteer_id})[0];
          // _this.reservationPurpose = _.filter(_this.reservationPurposesData, {id: res.data.data.reservation_purpose})[0];
          // _this.car = _.filter(_this.carsData, {id: res.data.data.car_id})[0];
          // _this.departureTime = res.data.data.departure_time;
          // _this.arrivalTime = res.data.data.arrival_time;
          console.log("reservationData: ", res.data.data);
          console.log("customer: ", _this.customer);
          console.log("coordinator: ", _this.coordinator);

          // setTimeout(function () {
          //   directionsService = new google.maps.DirectionsService;
          //   directionsDisplay = new google.maps.DirectionsRenderer;
          //   directionsDisplay.setMap(_this.$refs.map.$mapObject);
          //
          //   _this.setMapsDirections();
          //
          // }, 500)


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    calculateTaxiDepartureTimeFromHomeToDestination(departureTime, departureAddress, arrivalAddress) {
      const _this = this;

      this.$gmapApiPromiseLazy().then(() => {
        const directionsService = new google.maps.DirectionsService;
        const directionsDisplay = new google.maps.DirectionsRenderer;

        let request = {
          origin: departureAddress,
          destination: arrivalAddress,
          travelMode: 'DRIVING'
        };

        directionsService.route(request, function(result, status) {
          if (status == 'OK') {
            let travelTime = result.routes[0].legs[0].duration.value;
            _this.calculatedTaxiArrivalTime = _this.moment(departureTime, 'HH:mm').add(travelTime, 'seconds').format('HH:mm')
          }
        });

        console.log("directionsService", directionsService);
        console.log("directionsDisplay", directionsDisplay);
      });
    },

    calculateTaxiDepartureTimeFromDestinationToHome(arrivalTime, departureAddress, arrivalAddress) {
      const _this = this;

      this.$gmapApiPromiseLazy().then(() => {
        const directionsService = new google.maps.DirectionsService;

        let request = {
          origin: departureAddress,
          destination: arrivalAddress,
          travelMode: 'DRIVING'
        };

        directionsService.route(request, function(result, status) {
          if (status == 'OK') {
            let travelTime = result.routes[0].legs[0].duration.value;
            _this.calculatedTaxiDepartureTime = _this.moment(arrivalTime).subtract(travelTime, 'seconds').subtract(_this.intermodalTransportationThreshold, 'seconds').format('HH:mm');
            _this.calculatedTaxiArrivalTime = _this.moment(_this.calculatedTaxiDepartureTime, 'HH:mm').add(travelTime, 'seconds').format('HH:mm')
          }
        });

        console.log("directionsService", directionsService);
        console.log("directionsDisplay", directionsDisplay);
      });
    },
  },
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}

@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generateTravelPlanOuterContainer {
  font-family: 'Open Sans' !important;
  color: black !important;

  .generateTravelPlanContainer {
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 2% 7% 2% 7% !important;
    display: block !important;

    overflow: hidden !important;

    font-size: 10px;
    text-align: justify;

    page-break-inside: avoid;
    position: relative;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: 0;
      left: 0px;
      top: 0px;
    }

    .logo {
      float: right;
      height: 80px;
    }

    .headerSection {

      .title {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
      }

      .type {
        font-size: 24px;
        color: #126abf;
        font-weight: bold;
      }

      .date {
        font-size: 18px;
      }

      .infoSection {
        border-radius: 20px;
        padding: 0px 20px;

        .vs-alert {
          font-size: 13px;
          font-weight: bold;
          line-height: 36px;
          color: #126abf;

          h1 {
            font-size: 20px;
            font-weight: bold;
            color: #126abf;
          }
        }

      }


      .infoIcon {
        float: left;
        margin-top: 2px;
        margin-right: 10px;
        width: 30px;
        height: auto;
      }
    }

    .content {
      position: relative;

      h1 {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
      }

      h2 {
        font-size: 18px;
        font-weight: bold;
        color: #126abf;

        span {
          padding-left: 10px;
          color: #000000;
        }
      }

      .value {
        font-size: 14px;
        font-weight: normal !important;
      }

      h3 {
        font-size: 12px;
        font-weight: bold;
      }

      h4 {
        font-size: 11px;
        font-weight: bold;
      }

      .intermodalReservationOverview {

        .iconContainer {
          height: 130px;
          float: left;
          margin: 0px 10px 0px 0px;
        }

        .locationIcon {
          width: 80px;
          height: auto;
        }

        .arrowIcon {
          width: 80%;
          margin-top: -50px;
          padding: 0px 10px;
        }
      }

      .generalNotes {
        border-radius: 15px;
        border: 1px solid #126abf;
        padding: 15px;

        h2 {
          padding: 0px;
          padding-right: 5px;
          margin: 0px;
          color: #000000;
          display: inline;

          span {
            padding: 0px;
            padding-right: 5px;
            color: #126abf;
          }
        }
      }
    }

    .step {
      margin-bottom: 20px;

      .stepCounter {
        font-size: 13px;
        font-weight: bold;
        color: #126abf;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 8px 20px;
        margin-left: 20px;
        background: #B9CAE2 !important;
        display: inline-block;

        span {
          font-size: 18px;
        }
      }

      .transportationTypeIconContainer {
        display: inline-block;
        width: 100px;
        position: relative;

        .transportationTypeIcon {
          position: absolute;
          top: -35px;
          width: 100px;
          height: auto;
        }
      }

      .transportationTypeDescriptionContainer {
        display: inline-block;
        line-height: 13px;

        span {
          font-size: 15px;
          font-weight: bold;
          color: #126abf;
        }

        .operatorName {
          display: block;
          font-size: 13px;
          line-height: 15px;
          color: #000000;
          font-weight: normal;
        }
      }

      .stepInnerContainer {
        border-radius: 15px;
        border: 1px solid #126abf;
        padding: 15px;
        background: #E3E9F3 !important;
      }

      .delimiter {
        padding: 0px 10px;
      }
    }

    //.name {
    //  text-transform: uppercase;
    //  font-size: 18px;
    //  position: absolute;
    //  left: 306px;
    //  top: 203px;
    //  font-weight: bold;
    //}


    //.address {
    //  font-size: 12px;
    //  position: absolute;
    //  left: 165px;
    //  top: 249px;
    //  max-width: 385px;
    //  font-weight: bold;
    //}

  }

}
</style>
